import Vue from "vue";

export async function index() {
  const response = await Vue.prototype.$http.get(`/form_filler/forms`);

  return response.data;
}

export async function fillAndSendByEmail(payload) {
  const response = await Vue.prototype.$http.post(`form_filler/email`, payload);

  return response.data;
}
