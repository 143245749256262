<style lang="scss">
.attachments-table {
  table {
    tbody td {
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="attachments-table">
    <v-data-table
      :headers="headers"
      :items="attachments"
      :options.sync="options"
      @click:row="handleClickFile"
      show-select
      :value="value"
      @input="$emit('input', $event)"
      :items-per-page="15"
      :group-by="groupBy"
      :loading="loading"
      :mobile-breakpoint="$vuetify.breakpoint.thresholds.xs"
    >
      <template v-slot:top v-if="$vuetify.breakpoint.xs">
        <div class="pl-5">
          <v-card-text class="pb-0 font-weight-medium text--secondary">
            Filtro
          </v-card-text>
          <v-card-text class="pt-2">
            <v-row dense class="pl-1">
              <v-btn small block @click="categoryDialog = true">
                <v-icon small left>mdi-filter-menu</v-icon>
                {{ $capitalize($tc("model.documents_category_column_table")) }}
                <v-spacer />
                <v-icon color="red" v-if="categoryFilter.length"
                  >mdi-circle-medium</v-icon
                >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-row>
          </v-card-text>
        </div>
      </template>
      <template v-slot:header.category.description>
        <span>
          {{ $capitalize($tc("model.documents_category_column_table")) }}
          <v-badge
            color="error"
            dot
            overlap
            bordered
            :value="categoryFilter.length"
            class="mx-1"
          >
            <v-btn @click="categoryDialog = true" small icon>
              <v-icon small>mdi-filter-menu</v-icon>
            </v-btn>
          </v-badge>
        </span>
      </template>

      <template v-slot:group.header="{ toggle, isOpen, group, items }">
        <td @click="toggle" colspan="5">
          <v-icon v-if="isOpen" left>mdi-folder-open</v-icon>
          <v-icon v-else left>mdi-folder</v-icon>
          {{ group || $capitalize($tc("model.documents_no_categories")) }} ({{
            items.length
          }})
        </td>
        <td @click="toggle" class="text-right">
          <v-btn v-if="isOpen" left icon>
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <v-btn v-else left icon>
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </td>
      </template>

      <template v-slot:item.preview="{ item }">
        <v-avatar
          :color="getFilesIconColor(getFilesIcon(item)) || 'grey lighten-1'"
          tile
          class="white--text p-1"
          size="40"
        >
          <v-img v-if="isFileImage(item)" :src="item.public_url">
            <template v-slot:placeholder>
              <v-icon dark>mdi-image</v-icon>
            </template>
          </v-img>
          <v-icon v-else dark>
            {{ getFilesIcon(item) }}
          </v-icon>
        </v-avatar>
      </template>

      <template v-slot:item.category.description="{ item }">
        <v-chip
          @click.stop="handleClickCategory(item)"
          v-if="item.category"
          small
        >
          {{ item.category.description }}
        </v-chip>
        <v-chip @click.stop="handleClickCategory(item)" v-else small outlined>
          {{ $capitalize($tc("model.documents_no_categories")) }}
        </v-chip>
      </template>

      <template v-slot:item.name="{ item }">
        <span
          :class="{ 'info--text': item.has_notifications_to_manager }"
          v-text="truncateIfWordExceedsLength(item.name, 30)"
        >
        </span>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ formatDateTime(item.created_at) }}
      </template>

      <template v-slot:item.size="{ item }">
        {{ getFileSize(item) }}
      </template>

      <template v-slot:item.external_reference="{ item }">
        <div class="d-flex">
          <div
            class="col pl-1"
            v-for="visibility in getVisibilities(item)"
            :key="visibility.entity"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-badge
                  color="grey"
                  overlap
                  icon="mdi-lock"
                  :value="visibility.is_locked"
                >
                  <v-avatar
                    :color="visibility.color"
                    size="30"
                    v-on="on"
                    @click.prevent.stop="
                      handleClickVisibility(item, visibility.entity)
                    "
                  >
                    <v-icon dark small>{{ visibility.icon }}</v-icon>
                  </v-avatar>
                </v-badge>
              </template>
              <span>{{ visibility.tooltip }}</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>

    <attachment-category-filter-dialog
      v-model="categoryDialog"
      :categories="categories"
      :filter="categoryFilter"
      @change:filter="changeCategoryFilter"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="!$vuetify.breakpoint.xs ? '800px' : undefined"
    />
  </div>
</template>

<script>
import AttachmentsMixin from "@/mixins/AttachmentsMixin";
import i18n from "@/mixins/i18n";
import AttachmentCategoryFilterDialog from "@/components/Attachment/AttachmentCategoryFilterDialog";
import { formatDateTime } from "@/tools/date";

export default {
  name: "attachments-table",

  mixins: [AttachmentsMixin, i18n],

  components: {
    AttachmentCategoryFilterDialog,
  },

  props: {
    group: {
      type: Boolean,
      default: () => false,
    },
    attachments: Array,
    categories: Array,
    categoryFilter: Array,
    value: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      i18n_prefix: "documents",
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      category: null,
      formatDateTime,
      categoryDialog: false,
      headers: [
        {
          text: "",
          value: "preview",
          sortable: false,
          align: "center",
          width: "65",
        },
        {
          text: this.$capitalize(this.$tc("model.documents_files_title")),
          value: "name",
        },
        {
          text: this.$capitalize(
            this.$tc("model.documents_extension_column_table")
          ),
          value: "extension",
        },
        {
          text: this.$capitalize(
            this.$tc("model.documents_category_column_table")
          ),
          value: "category.description",
          width: "160",
        },
        {
          text: this.$capitalize(
            this.$tc("model.documents_visibility_column_table")
          ),
          value: "external_reference",
          width: "110",
        },
        {
          text: this.$capitalize(
            this.$tc("model.documents_creation_date_column_table")
          ),
          value: "created_at",
          width: "150",
        },
      ],
    };
  },

  methods: {
    handleClickVisibility(file, entity) {
      this.$listeners["click:visibility"](file, entity);
    },
    handleClickFile(event) {
      this.$emit("click:attachment", event);
    },
    changeCategoryFilter(value) {
      this.$emit("update:categoryFilter", value);
    },
    handleClickCategory(item) {
      this.$emit("click:category", item);
    },
    getFileSize(item) {
      return (item.size / 1024 / 1024).toFixed(2) + " MB";
    },

    getVisibilityColor(visible, color) {
      return visible ? color : "blue-grey lighten-4";
    },
    getVisibilityTooltip(visible, entity) {
      switch (entity) {
        case "unity":
          return visible
            ? this.__("Visível para unidade")
            : this.__("Invisível para unidade");
        case "insurer":
          return visible
            ? this.__("Visível para companhia")
            : this.__("Invisível para companhia");
      }
    },

    getVisibilities(item) {
      return [
        {
          entity: "insurer",
          color: this.getVisibilityColor(item.visible_to_insurer, "cyan"),
          tooltip: this.getVisibilityTooltip(
            item.visible_to_insurer,
            "insurer"
          ),
          icon: "mdi-domain",
          is_locked: item.is_locked,
        },

        {
          entity: "unity",
          color: this.getVisibilityColor(item.visible_to_unity, "indigo"),
          tooltip: this.getVisibilityTooltip(item.visible_to_unity, "unity"),
          icon: "mdi-account-hard-hat",
          is_locked: false,
        },
      ];
    },
    truncateIfWordExceedsLength(string, maxWordLength) {
      let words = string.split(/[\s-]/);

      for (let word of words) {
        if (word.length > maxWordLength) {
          return string.substring(0, maxWordLength) + "...";
        }
      }
      return string;
    },
  },

  watch: {
    categoryFilter(value) {
      this.options = {
        ...this.options,
        page: 1,
      };
    },
  },

  computed: {
    groupBy() {
      return this.group === true ? ["category.description"] : [];
    },
    lead() {
      return this.$store.getters.getLead;
    },
    insurer_portal_companhia() {
      return this.lead.insurer.portal_companhia;
    },
  },
};
</script>
