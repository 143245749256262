<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card :disabled="submitting">
      <v-form @submit.prevent="handleSubmit">
        <v-card-title>
          Formulários
          <v-spacer></v-spacer>
          <v-btn @click="handleClose" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list style="margin-left: -10px">
            <v-subheader>ESCOLHA OS FORMULÁRIOS</v-subheader>
            <v-list-item-group v-model="selectedForms" color="primary" multiple>
              <v-list-item v-for="form in forms" :key="form" :value="form">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title v-text="form"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <!-- <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon> -->
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-text-field
            v-model="email"
            label="Destinatário"
            prepend-inner-icon="mdi-email"
            type="email"
            filled
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            color="primary"
            block
            large
            :disabled="!canSubmit"
            :loading="submitting"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  index,
  fillAndSendByEmail,
} from "@/services/form_filler/form_filler.js";

export default {
  props: {
    lead_number: String,
  },

  data() {
    return {
      dialog: false,
      email: "",
      forms: [],
      selectedForms: [],
      submitting: false,
    };
  },

  methods: {
    handleClose() {
      this.dialog = false;
    },
    async handleSubmit() {
      this.submitting = true;

      try {
        await fillAndSendByEmail({
          files: this.selectedForms,
          lead_number: this.lead_number,
          email: this.email,
        });
        this.handleClose();
      } finally {
        this.submitting = false;
      }
    },
    init() {
      this.dialog = true;
      this.email = "";
      this.selectedForms = [];
    },
    async loadForms() {
      const response = await index();
      this.forms = response.data;
    },
  },

  computed: {
    canSubmit() {
      if (this.selectedForms.length === 0) {
        return false;
      }

      if (!this.email) {
        return false;
      }

      return true;
    },
  },

  created() {
    this.loadForms();
  },
};
</script>
