<template>
  <v-dialog :value="value" @change="handleChange" persistent v-bind="$attrs">
    <v-card>
      <v-app-bar flat>
        <v-toolbar-title>
          {{ dialogTitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-list>
        <v-list-item v-if="!disable_lock_change">
          <v-list-item-content class="pl-3">
            <v-list-item-subtitle class="text-wrap">
              {{
                __(
                  "O bloqueio determinará se o anexo pode ser enviado para a entidade ou não. Caso bloqueado, um anexo não poderá se tornar visível."
                )
              }}</v-list-item-subtitle
            >
            <v-switch v-model="form.is_locked" :label="__('Bloqueado')" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="integrated">
          <v-list-item-content class="pl-3">
            <v-list-item-subtitle>{{
              __(
                "A visibilidade determina se o anexo está disponível para visualização da entidade."
              )
            }}</v-list-item-subtitle>
            <v-switch
              v-model="form.visible"
              :label="__('Visível')"
              :disabled="disable_visibility_change"
              no-details
            />
            <v-list-item-subtitle
              v-if="visibility_change_warning"
              class="pt-0 warning--text"
            >
              <v-icon color="warning" small>mdi-alert</v-icon>
              {{ __("O anexo está bloqueado e visível") }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="submit" :loading="submitting" color="primary">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/mixins/i18n";
import { changeAttachmentLockAndVisibility } from "@/services/attachment";

export default {
  name: "attachment-change-visibility-dialog",
  mixins: [i18n],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: String,
    },
    file: {
      type: Object,
    },
  },
  data() {
    return {
      i18n_prefix: "document",
      form: {
        visible: false,
        is_locked: false,
      },
      submitting: false,
    };
  },
  methods: {
    handleChange($event) {
      this.$emit("input", $event);
    },
    close() {
      this.$emit("input", false);
      this.resetForm();
    },
    cancel() {
      this.close();

      if (this.$listeners.cancel) {
        this.$listeners.cancel();
      }
    },
    resetForm() {
      this.form = {
        visible: false,
        is_locked: false,
      };
    },
    async submit() {
      this.submitting = true;
      await changeAttachmentLockAndVisibility(
        this.file.id,
        this.entity,
        this.form.visible,
        this.form.is_locked
      );
      if (this.$listeners.submit) {
        this.$listeners.submit();
      }
      this.close();
      this.submitting = false;
    },

    setInitialValue() {
      this.form.visible = this.visible;
      this.form.is_locked = this.is_locked;
    },
  },
  computed: {
    dialogTitle() {
      switch (this.entity) {
        case "insurer":
          return this.__(
            "Alterar bloqueio/visibilidade do anexo para a companhia"
          );
        case "unity":
          return this.__(
            "Alterar bloqueio/visibilidade do anexo para a unidade"
          );
      }

      return "";
    },
    visible() {
      switch (this.entity) {
        case "insurer":
          return this.file.visible_to_insurer;
        case "unity":
          return this.file.visible_to_unity;
      }

      return false;
    },
    is_locked() {
      switch (this.entity) {
        case "insurer":
          return this.file.is_locked;
        case "unity":
          return false;
      }

      return false;
    },
    disable_lock_change() {
      return this.entity !== "insurer";
    },
    disable_visibility_change() {
      return this.form.is_locked && !this.form.visible;
    },
    visibility_change_warning() {
      return this.form.is_locked && this.form.visible;
    },
    lead() {
      return this.$store.getters.getLead;
    },
    integrated() {
      if (this.entity === "insurer") {
        return this.lead.insurer.portal_companhia;
      }

      return true;
    },
  },
  watch: {
    file() {
      this.setInitialValue();
    },
  },
};
</script>

<style></style>
