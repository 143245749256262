<style lang="scss" scoped>
.file-tab {
  min-height: 600px;

  .over {
    background-color: red;
  }
}
</style>

<template>
  <div class="file-tab">
    <v-row>
      <v-col cols="12">
        <input
          type="file"
          ref="file"
          @change="handleUploadFiles"
          v-show="false"
          accept="application/pdf, image/png, image/jpeg, text/*, .docx, .pptx, .xlsx, .msg"
          multiple
        />
        <drop
          @drop="handleDrop"
          @dragover="realTimeOver = true"
          @dragleave="realTimeOver = false"
        >
          <div>
            <v-card outlined>
              <v-overlay absolute :value="over" color="primary">
                <div class="text-h4">
                  <v-icon large left>mdi-paperclip</v-icon>
                  {{
                    $capitalize($tc("model.documents.attach_documents_button"))
                  }}
                </div>
              </v-overlay>
              <v-card-title>
                {{
                  $capitalize($tc("model.documents_document_management_title"))
                }}
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="search"
                      :label="$capitalize($tc('model.documents_search_attach'))"
                      single-line
                      outlined
                      dense
                      hide-details
                      clearable
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="text-right">
                    <v-btn @click="getFiles" icon :loading="loading_files">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                      @click="handleClickGenerateForms()"
                      color="primary"
                      outlined
                      class="mr-1"
                    >
                      Gerar Formulários
                    </v-btn>
                    <v-btn
                      @click="openFilesDialog"
                      :disabled="uploading"
                      color="primary"
                    >
                      <v-icon size="24" left>mdi-plus</v-icon
                      >{{
                        $capitalize(
                          $tc("model.documents_new_attachment_button")
                        )
                      }}
                      <span v-if="uploadCount > 0">({{ uploadCount }})</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-tabs v-model="groupTab">
                <v-tab :value="0">{{
                  $capitalize($tc("model.documents_in_list_tab"))
                }}</v-tab>
                <v-tab :value="1">{{
                  $capitalize($tc("model.documents_by_categories_tab"))
                }}</v-tab>
              </v-tabs>
              <v-divider></v-divider>
              <v-sheet>
                <v-row class="ml-2 py-2">
                  <v-col v-if="selectedFiles.length" cols="3">
                    <span class="grey--text">
                      {{ selectedFiles.length }}
                      {{
                        selectedFiles.length == 1
                          ? this.$capitalize(
                              this.$tc("model.documents_file_count")
                            )
                          : this.$capitalize(
                              this.$tc("model.documents_files_count")
                            )
                      }}
                    </span>
                    <v-btn @click="selectedFiles = []" icon small>
                      <v-icon small>mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="text-right">
                    <AttachmentMultipleVisibilityButton
                      @change_visibility="
                        handleChangeInsurerVisibilityMultipleFiles
                      "
                      label="Companhia"
                      icon="mdi-domain"
                      color="cyan"
                      text
                      small
                      :disabled="selectedFiles.length < 1"
                      v-if="insurer_portal_companhia"
                    />
                    <AttachmentMultipleVisibilityButton
                      @change_visibility="
                        handleChangeUnityVisibilityMultipleFiles
                      "
                      label="Unidade"
                      icon="mdi-account-hard-hat"
                      color="indigo"
                      text
                      small
                      :disabled="selectedFiles.length < 1"
                    />
                    <v-btn
                      @click="handleCategorizeFiles"
                      class="mr-2"
                      color="info"
                      text
                      small
                      :disabled="selectedFiles.length < 1"
                    >
                      <v-icon left>mdi-folder-edit</v-icon>
                      {{
                        $capitalize($tc("model.documents_categorize_button"))
                      }}
                    </v-btn>
                    <v-btn
                      @click="handleDestroyMultipleFiles"
                      :loading="deleting"
                      class="mr-2"
                      color="red"
                      text
                      small
                      :disabled="selectedFiles.length < 1"
                    >
                      <v-icon left>mdi-delete</v-icon>
                      {{ $capitalize($tc("model.documents_delete_button")) }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
              </v-sheet>
              <attachments-table
                :attachments="filteredFiles"
                :categories="folders"
                :categoryFilter.sync="categoryFilter"
                @click:category="handleClickCategory"
                @click:attachment="handleClickAttachment"
                @click:visibility="handleChangeVisibility"
                v-model="selectedFiles"
                :group="group"
                :loading="uploading"
              />
            </v-card>
          </div>
        </drop>

        <file-dialog
          v-model="file_preview"
          :attachment="file"
          @updateFile="updateFile"
          @deleteFile="deleteFile"
        />

        <attachment-category-dialog
          ref="categoryDialog"
          :categories="folders"
          :loading="categoryLoading"
          @submit="handleUpdateCategory"
          @submit:multiple="handleMultipleUpdateCategory"
        />

        <attachment-change-visibility-dialog
          v-model="visibilityDialog"
          :entity="visibilityEntity"
          :file="visibilityFile"
          :fullscreen="$vuetify.breakpoint.xs"
          :max-width="!$vuetify.breakpoint.xs ? '600px' : undefined"
          @submit="getFiles"
        />

        <v-dialog
          v-model="sendToErrorDialog"
          :max-width="!$vuetify.breakpoint.xs ? '600px' : undefined"
        >
          <v-card>
            <v-app-bar flat>
              <v-toolbar-title>{{
                __("Arquivos não permitidos")
              }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="sendToErrorDialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-app-bar>
            <v-list>
              <span
                v-for="(message, index) in sendToErrorMessages"
                :key="'messages-' + index"
              >
                <v-subheader>
                  <span style="max-width: 300px" class="text-wrap">
                    {{ message.text }}
                  </span>
                </v-subheader>
                <v-list-item
                  v-for="file in message.files"
                  :key="'messages-file' + file.id"
                >
                  <v-list-item-icon>
                    <v-icon color="red">mdi-circle-medium</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ file.name }}.{{ file.extension }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      file.category
                        ? file.category.description
                        : "Sem categorias"
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </span>
            </v-list>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <FormFillerDialog
      ref="form_filler"
      :lead_number="$route.params['lead_number']"
    />
  </div>
</template>

<script>
import FileDialog from "../FileDialog.vue";
import i18n from "@/mixins/i18n";
import AttachmentsTable from "@/components/Lead/AttachmentsTable";
import AttachmentCategoryDialog from "@/components/Lead/AttachmentCategoryDialog";
import AttachmentChangeVisibilityDialog from "@/components/Attachment/AttachmentChangeVisibilityDialog";
import { search as arraySearch } from "@/tools/array";
import {
  index,
  store,
  update,
  multipleDestroy,
  multipleUpdateCategory,
  changeManyAttachmentsVisibility,
} from "@/services/attachment";
import { index as indexCategories } from "@/services/attachment_category";
import { Drop } from "vue-drag-drop";
import FormFillerDialog from "../FormFiller/FormFillerDialog.vue";
import AttachmentMultipleVisibilityButton from "@/components/Attachment/AttachmentMultipleVisibilityButton.vue";

export default {
  name: "files-tab",
  mixins: [i18n],
  components: {
    FileDialog,
    AttachmentsTable,
    AttachmentCategoryDialog,
    AttachmentChangeVisibilityDialog,
    Drop,
    FormFillerDialog,
    AttachmentMultipleVisibilityButton,
  },
  data: () => ({
    i18n_prefix: "documents",
    folders: [],
    files: [],
    file_preview: false,
    file: {},
    loading_files: false,
    categoryLoading: false,
    search: "",
    categoryFilter: [],
    selectedFiles: [],
    uploadCount: 0,
    deleting: false,
    uploading: false,
    updating_visibility_insurer: false,
    updating_visibility_unity: false,
    groupTab: 0,
    over: false,
    realTimeOver: false,

    visibilityDialog: false,
    visibilityEntity: null,
    visibilityFile: null,

    sendToErrorDialog: false,
    sendToErrorMessages: [],
  }),
  methods: {
    handleClickGenerateForms() {
      this.$refs.form_filler.init();
    },
    handleChangeVisibility(file, entity) {
      this.visibilityDialog = true;

      this.visibilityFile = file;
      this.visibilityEntity = entity;
    },
    async updateFileLocked(item, is_locked) {
      const response = await this.$http.post(
        `attachments/${item.id}/update_lock`,
        {
          is_locked,
        }
      );

      this.files = this.files.map((file) => {
        if (file.id == response.data.id) {
          return {
            ...file,
            is_locked: response.data.is_locked,
          };
        }

        return file;
      });
    },
    handleCategorizeFiles() {
      this.$refs.categoryDialog.initMultiple(this.selectedFiles);
    },
    handleClickAttachment(attachment) {
      this.file = attachment;
      this.file_preview = true;
    },
    async handleMultipleUpdateCategory(payload) {
      const { files, category_id } = payload;

      this.categoryLoading = true;
      try {
        const response = await multipleUpdateCategory(
          category_id,
          files.map((file) => file.id)
        );
        const newFiles = response.attachments;

        this.files = this.files.map((file) => {
          const newFile = newFiles.find((newFile) => newFile.id === file.id);

          if (!newFile) return file;

          return {
            ...file,
            category: newFile.category,
            category_id: newFile.category_id,
            is_locked: newFile.is_locked,
          };
        });

        this.$refs.categoryDialog.close();
        this.selectedFiles = [];
        this.$store.commit(
          "alert",
          `${newFiles.length} ` +
            this.$capitalize(
              this.$tc("model.documents_categorized_attachments_alert")
            )
        );
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.categoryLoading = false;
      }
    },
    async handleDestroyMultipleFiles() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title:
          this.$capitalize(
            this.$tc("model.documents_delete_notification_card_title")
          ) +
          ` ${this.selectedFiles.length} ` +
          this.$tc("model.documents_attachment_notification_card_title"),
        description: this.$capitalize(
          this.$tc("model.documents_action_remove_file_message_text")
        ),
        positive_action: () => this.destroyMultipleFiles(),
      });
    },
    async destroyMultipleFiles() {
      const files = this.selectedFiles.map((file) => file.id);

      this.deleting = true;
      try {
        const response = await multipleDestroy(files);
        const deletedFiles = response.deleted;
        const fails = response.errors;

        this.files = this.files.filter(
          (file) => !deletedFiles.includes(file.id)
        );
        this.selectedFiles = [];
        this.$store.commit(
          "alert",
          `${deletedFiles.length}` +
            this.$tc("model.documents_deleted_files_alert") +
            `, ${fails.length}` +
            this.$tc("model.documents_error_alert")
        );
      } catch (error) {
        this.$store.commit("error", error);
      } finally {
        this.deleting = false;
      }
    },
    async handleChangeInsurerVisibilityMultipleFiles(visibility) {
      if (this.cantSendInsurerMessages.length > 0) {
        this.sendToErrorDialog = true;
        this.sendToErrorMessages = this.cantSendInsurerMessages;
        return;
      }

      this.$store.dispatch("confirm_dialog/confirm", {
        title: `Enviar ${this.selectedFiles.length} anexo(s)`,
        description:
          "A ação irá dar visibilidade de todos os arquivos selecionados para a companhia",
        positive_action: async () => {
          this.updating_visibility_insurer = true;
          this.changeVisibilityMultipleFiles("insurer", visibility);
          this.updating_visibility_insurer = false;
        },
      });
    },
    async handleChangeUnityVisibilityMultipleFiles(visibility) {
      if (this.cantSendMessages.length > 0) {
        this.sendToErrorDialog = true;
        this.sendToErrorMessages = this.cantSendMessages;
        return;
      }

      this.$store.dispatch("confirm_dialog/confirm", {
        title: `Alterar ${this.selectedFiles.length} anexo(s)`,
        description:
          "A ação irá alterar a visibilidade de todos os arquivos selecionados para a unidade",
        positive_action: async () => {
          this.updating_visibility_unity = true;
          this.changeVisibilityMultipleFiles("unity", visibility);
          this.updating_visibility_unity = false;
        },
      });
    },
    async changeVisibilityMultipleFiles(entity, visibility = true) {
      const files = this.selectedFiles.map((file) => file.id);

      try {
        const response = await changeManyAttachmentsVisibility(
          files,
          entity,
          visibility
        );
        const updatedFiles = response;

        this.files = this.files.filter(
          (file) => !updatedFiles.includes(file.id)
        );
        this.getFiles();
        this.selectedFiles = [];
        this.$store.commit(
          "alert",
          `${updatedFiles.length} anexo(s) afetado(s)`
        );
      } catch (error) {
        this.$store.commit("error", error);
      }
    },
    async handleUpdateCategory(payload) {
      const { file, category_id } = payload;

      this.categoryLoading = true;
      try {
        const response = await update({ category_id }, file.id);

        this.files = this.files.map((item) => {
          if (item.id == file.id) {
            return {
              ...item,
              category: response.category,
              category_id: response.category_id,
              is_locked: response.is_locked,
            };
          }

          return item;
        });

        this.$refs.categoryDialog.close();
      } catch (error) {
        this.$store.commit(
          "error",
          this.$capitalize(
            this.$tc("model.documents_failed_change_category_alert")
          )
        );
      } finally {
        this.categoryLoading = false;
      }
    },
    handleClickCategory(file) {
      this.$refs.categoryDialog.init(file);
    },
    deleteFile(file_deleted) {
      this.files = this.files.filter((file) => {
        return file.id !== file_deleted;
      });
      this.file_preview = false;
    },
    updateFile(file_changed) {
      this.files = this.files.map((file_for_change) => {
        return file_for_change.id === file_changed.id
          ? { ...file_changed }
          : file_for_change;
      });
    },
    openFilesDialog() {
      this.$refs.file.click();
    },
    handleDrop(data, event) {
      event.preventDefault();
      this.realTimeOver = false;
      if (this.uploading) {
        this.$store.commit(
          "alert",
          +this.$capitalize(this.$tc("model.documents_wait_for_uploads_alert"))
        );
        return;
      }

      const files = event.dataTransfer.files;

      this.uploadFiles(files);
    },
    async uploadFiles(files) {
      this.uploading = true;
      this.uploadCount = files.length;
      try {
        for (let file of files) {
          await this.delay(1000);
          const response = await this.addFile(file);
          this.files.push({ ...response });
          this.uploadCount -= 1;
        }
        this.$store.commit(
          "alert",
          `${files.length} ` +
            this.$tc("model.documents_uploaded_attachments_alert")
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.$store.commit("sendMessage", {
            text:
              e.response.data.errors.attachment[0] ||
              this.$$capitalize($tc("model.documents_upload_failed_message")),
            color: "red",
          });

          return;
        }

        this.$store.commit("sendMessage", {
          text: e.response.data.message,
          color: "red",
        });
      } finally {
        this.uploading = false;
      }

      this.$refs.file.value = "";
    },
    async handleUploadFiles($event) {
      await this.uploadFiles($event.target.files);

      this.$refs.file.value = "";
    },
    addFile(file, category_id = null, description = "") {
      let data = new FormData();
      data.append("attachment", file);
      if (category_id !== null) data.append("category_id", category_id);
      data.append("description", description);

      return store(this.$route.params["lead_number"], data);
    },
    delay(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    },
    async downloadFile(file) {
      window.open(file.public_url);
    },
    open_file_preview(file) {
      this.file_preview = true;
      this.file = { ...file };
    },
    async getFolders() {
      this.folders = await indexCategories();
    },
    async getFiles(silent = false) {
      this.loading_files = true;
      if (silent == false) {
        this.files = [];
      }
      this.files = await index(this.$route.params["lead_number"]);
      this.loading_files = false;
    },
    getFilesInFolder(folder_id) {
      return this.files.filter((file) => {
        return file.category_id == folder_id;
      });
    },
  },
  computed: {
    cantSendMessages() {
      const messages = [];

      if (this.uncategorized_selected_files.length > 0) {
        messages.push({
          text: this.__(
            "Não é possível enviar pois há arquivos selecionados sem categorização:"
          ),
          files: this.uncategorized_selected_files,
        });
      }

      return messages;
    },

    cantSendInsurerMessages() {
      const messages = this.cantSendMessages;

      if (this.insurer_locked_selected_files.length > 0) {
        messages.push({
          text: this.__(
            "Não é possível enviar pois há arquivos selecionados com bloqueio de envio:"
          ),
          files: this.insurer_locked_selected_files,
        });
      }

      return messages;
    },
    group() {
      return this.groupTab === 0 ? false : true;
    },
    filteredFiles() {
      let files = arraySearch(this.search, this.files, (item) => item.name);

      if (this.categoryFilter.length) {
        files = files.filter((file) => {
          return (
            this.categoryFilter.includes(file.category_id) ||
            (this.categoryFilter.includes(0) && file.category_id == null)
          );
        });
      }

      return files;
    },
    cantSendToInsurer() {
      return (
        this.insurer_locked_selected_files.length > 0 ||
        this.uncategorized_selected_files.length > 0
      );
    },
    cantSendToUnity() {
      return this.uncategorized_selected_files.length > 0;
    },
    insurer_locked_selected_files() {
      return this.selectedFiles.filter((file) => file.is_locked);
    },
    uncategorized_selected_files() {
      return this.selectedFiles.filter((file) => file.category_id == null);
    },
    lead() {
      return this.$store.getters.getLead;
    },
    insurer_portal_companhia() {
      return this.lead.insurer.portal_companhia;
    },
  },
  watch: {
    // Elimina ruídos no drop
    realTimeOver: _.debounce(function (value) {
      this.over = value;
    }, 100),
  },
  created() {
    this.getFolders();
    this.getFiles();
  },
};
</script>
