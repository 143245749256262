<template>
    <v-dialog v-model="dialog" max-width="400" scrollable>
        <v-card :disabled="loading">
            <v-card-title>
                {{ $capitalize($tc('model.documents_choose_category')) }}
                <v-spacer></v-spacer>
                <v-btn @click="handleClickCancel" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <div class="my-2 mx-5">
                <v-text-field 
                    v-model="search" 
                    autofocus 
                    class="m-4" 
                    outlined 
                    dense 
                    :label="$capitalize($tc('model.documents_search_attach'))"  
                    hide-details 
                    single-line
                    clearable
                    @click:clear="search = ''"
                ></v-text-field>
            </div>
            <v-divider></v-divider>
            <v-card-text style="height: 350px;">
                <div v-if="sugestions.length">
                    <v-subheader class="pl-0">{{ $capitalize($tc('model.documents_suggestions_header')) }}</v-subheader>
                    <v-radio-group
                        v-model="category"
                        column
                    >
                        <v-radio 
                            v-for="cat in sugestions" 
                            :key="cat.id" 
                            :label="cat.description" 
                            :value="cat.id"
                        ></v-radio>
                    </v-radio-group>

                    <v-divider></v-divider>

                    <v-subheader class="pl-0">{{ $capitalize($tc('model.documents_all_header')) }}</v-subheader>
                </div>
                <v-radio-group
                    v-model="category"
                    column
                >
                        <v-radio 
                            :label="$capitalize($tc('model.documents_no_categories'))"  
                            :value="0"
                            key="null"
                            v-show="!search"
                        >
                        </v-radio>
                    <v-radio 
                        v-for="cat in orderedCategories" 
                        v-show="filteredCategories.find(item => item.id === cat.id)"
                        :key="cat.id" 
                        :label="cat.description" 
                        :value="cat.id"
                    ></v-radio>
                </v-radio-group>
                <div v-if="filteredCategories.length < 1" class="text-center">
                   {{ $capitalize($tc('model.documents_no_categories_found')) }} <br>
                    "<b>{{ search }}</b>"
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="handleClickCancel" elevation="0">
                   {{ $capitalize($tc('model.documents_cancel_button')) }}
                </v-btn>
                <v-btn :loading="loading" @click="handleClickSave" color="primary">
                    {{ $capitalize($tc('model.documents_save_button')) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import AttachmentsMixin from "@/mixins/AttachmentsMixin";
import { search as arraySearch } from '@/tools/array';

export default {
    name: 'attachment-category-dialog',

    mixins: [ AttachmentsMixin ],

    props: {
        categories: Array,
        loading: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            dialog: false,
            file: null,
            files: [],
            category: null,
            search: ""
        };
    },
    
    methods: {
        init(file) {
            this.file = file;
            this.files = [];
            this.category = file.category_id || 0;
            this.dialog = true;
            this.search = "";
        },
        initMultiple(files) {
            if(files.length === 1) {
                this.init(files[0]);
                return;
            }

            this.file = null;
            this.files = files;
            this.category = 0;
            this.search = "";
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        handleClickCancel() {
            this.dialog = false;
            this.$emit('cancel');
        },
        handleClickSave() {
            if(this.files.length) {
                this.$emit('submit:multiple', {
                    category_id: this.category == 0 ? null : this.category,
                    files: this.files
                });    
            } else {
                this.$emit('submit', {
                    category_id: this.category == 0 ? null : this.category,
                    file: this.file
                });
            }
        },
        /**
         * Retorna apenas as categorias sugeridas e ordenadas consoante à sugestão
         * Ex.: Se o sugerido for [4,2] retorna "Relatório de Visita e Orçamento externo" nessa ordem.
         */
        sugest(sugestionIds) {
            return this.categories.filter(item => {
                return sugestionIds.includes(item.id);
            }).sort((a,b) => {
                if(sugestionIds.indexOf(a.id) > sugestionIds.indexOf(b.id)) {
                    return 1;
                }

                if(sugestionIds.indexOf(a.id) < sugestionIds.indexOf(b.id)) {
                    return -1;
                }

                return 0;
            });
        }
    },

    computed: {
        orderedCategories() {
            const categories = [...this.categories];
            categories.sort((a, b) => {
                if(a.description > b.description) {
                    return 1;
                }

                if(a.description < b.description) {
                    return -1;
                }

                return 0;
            });

            return categories;
        },
        filteredCategories() {
            if(!this.search) return [...this.orderedCategories];

            return arraySearch(this.search, this.orderedCategories, item => item.description);
        },
        sugestions() {
            if(!this.file) return [];

            // Imagens
            if(this.isFileImage(this.file)) {
                return this.sugest([1, 2, 4]);
            }

            // Relatório final
            if(this.file.name.startsWith('final_report')) {
                return this.sugest([7]);
            }

            // Emails
            if(this.compareExtension(this.file, 'msg')) {
                return this.sugest([11, 13]);
            }

            // PDFs
            if(this.compareExtension(this.file, 'pdf')) {
                return this.sugest([13, 5, 18, 2, 4]);
            }

            // Word
            if(this.compareExtension(this.file, 'doc')) {
                return this.sugest([2, 4]);
            }

            return [];
        }
    }
}
</script>