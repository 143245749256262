import { removeAccents } from "./string";

export function groupBy(items = [], keyGetter) {
  const map = new Map();

  items.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map);
}

export function distinctById(items = [], keyGetter) {
  const collection = [];

  items.forEach(item => {
    const key = keyGetter(item);
    if (!collection.find(col => keyGetter(col) == key))
      collection.push(item);
  });

  return collection;
}

export function search(text, items, searchFunction = (item) => item) {
  if(!text) return [...items];

  const preparedSearch = removeAccents(text.toLowerCase());
  const keyWords = preparedSearch.split(' ');

  return items.filter(item => {
    const preparedDescription = removeAccents(searchFunction(item).toLowerCase());
    let found = true;

    keyWords.forEach(key => {
        found = (preparedDescription.indexOf(key) > -1) == false ? false : found;
    });

    return found;
  });
}
