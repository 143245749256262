<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="{ ...attrs, ...$attrs }" v-on="on">
        <v-icon v-text="icon" left></v-icon>
        {{ label }}
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="handleChangeVisibility(true)">
        <v-list-item-icon>
          <v-icon>mdi-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Dar visibilidade </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="handleChangeVisibility(false)">
        <v-list-item-icon>
          <v-icon>mdi-eye-off</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Retirar visibilidade </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  mixins: [],

  components: {},

  props: {
    icon: String,
    label: String,
  },

  data() {
    return {};
  },

  methods: {
    handleChangeVisibility(visibility) {
      this.$emit("change_visibility", visibility);
    },
  },

  computed: {},
};
</script>
